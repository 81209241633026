import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import contacts from '@src/views/contacts/store'
import licenses from '@src/views/licenses/store'
import progress from '@src/views/progress/store'
import invoices from '@src/views/invoices/store'

const rootReducer = {
  auth,
  navbar,
  layout,
  contacts,
  licenses,
  progress,
  invoices
}

export default rootReducer
