import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const getData = createAsyncThunk('appLicenses/getData', async params => {
  const response = await axios.get('/umbraco/api/LicensesApi/Get', { params })
  return {
      data: response.data.content
  }
})

export const getPortalLicenses = createAsyncThunk('appLicenses/getPortalLicenses', async params => {
  const response = await axios.get('/umbraco/api/LicensesApi/GetPortalLicenses', { params })
  return {
      data: response.data.content
  }
})

export const getDashboard = createAsyncThunk('appLicenses/getDashboard', async params => {
  const response = await axios.get('/umbraco/api/LicensesApi/GetDashboard', { params })
  return {
      data: response.data.content
  }
})

export const getLicense = createAsyncThunk('appLicenses/getLicense', async id => {
  const response = await axios.get(`/umbraco/api/LicensesApi/GetLicense?id=${id}`)
  return response.data.content
})


export const getLicenseOrders = createAsyncThunk('appLicenses/getLicenseOrders', async params => {
  const response = await axios.get('/umbraco/api/LicensesApi/GetLicenseOrders', { params })
  return {
      data: response.data.content
  }
})

export const getLicenseOrder = createAsyncThunk('appLicenses/getLicenseOrder', async id => {
  const response = await axios.get(`/umbraco/api/LicensesApi/GetLicenseOrder?id=${id}`)
  return response.data.content
})

export const appLicensesSlice = createSlice({
  name: 'appLicenses',
  initialState: {
    loading: 'idle',
    data: {},
    total: 1,
    params: {},
    selectedLicense: null,
    selectedLicenseOrder: null,
    dataOrders: {},
    totalOrders: 1
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.total = action.payload.data.total
        state.loading = 'idle'
      })
      .addCase(getData.pending, (state) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
        }
      })
      .addCase(getDashboard.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.total = action.payload.data.total
        state.loading = 'idle'
      })
      .addCase(getDashboard.pending, (state) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
        }
      })
      .addCase(getLicense.fulfilled, (state, action) => {
        if (state.loading === 'pending') {
        state.selectedLicense = action.payload
        state.loading = 'idle'
        }
      })
      .addCase(getLicense.pending, (state) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
        }
      })
      .addCase(getLicenseOrder.fulfilled, (state, action) => {
        if (state.loading === 'pending') {
        state.selectedLicenseOrder = action.payload
        state.loading = 'idle'
        }
      })
      .addCase(getLicenseOrder.pending, (state) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
        }
      })
      .addCase(getLicenseOrders.fulfilled, (state, action) => {
        if (state.loading === 'pending') {
        state.dataOrders = action.payload.data
        state.totalOrders = action.payload.data.total
        state.loading = 'idle'
        }
      })
      .addCase(getLicenseOrders.pending, (state) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
        }
      })
      .addCase(getPortalLicenses.fulfilled, (state, action) => {
        if (state.loading === 'pending') {
          state.data = action.payload.data
          state.total = action.payload.data.total
          state.loading = 'idle'
        }
      })
      .addCase(getPortalLicenses.pending, (state) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
        }
      })
  }
})

export default appLicensesSlice.reducer
