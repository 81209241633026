import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const getData = createAsyncThunk('appProgress/getData', async params => {
  const response = await axios.get('/umbraco/api/ProgressApi/Get', { params })

  return {
    data: response.data.content
  }

})

export const getProject = createAsyncThunk('appProgress/getProject', async id => {
  const response = await axios.get(`/umbraco/api/ProgressApi/GetProject?id=${id}`)  
  return response.data.content
})

export const appProgressSlice = createSlice({
  name: 'appProgress',
  initialState: {
    loading: 'idle',
    data: {},
    total: 1,
    params: {},
    selectedProject: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data.projects
        state.total = action.payload.data.totalCount
      })
      .addCase(getProject.pending, (state) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
        }
      })
      .addCase(getProject.fulfilled, (state, action) => {
        if (state.loading === 'pending') {
          state.selectedProject = action.payload
          state.loading = 'idle'
        }
      })
  }
})

export default appProgressSlice.reducer
