import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const getData = createAsyncThunk('appInvoices/getData', async params => {
  const response = await axios.get('/umbraco/api/InvoicesApi/Get', { params })

  return {
    data: response.data.content
  }

})

export const appInvoicesSlice = createSlice({
  name: 'appInvoices',
  initialState: {
    loading: 'idle',
    data: {},
    axCustomer: {},
    total: 1,
    params: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data.invoices
        state.axCustomer = action.payload.data.axCustomer
        state.total = action.payload.data.totalCount
      })
  }
})

export default appInvoicesSlice.reducer
