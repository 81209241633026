import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const getData = createAsyncThunk('appContacts/getAllData', async () => {
    const response = await axios.get('/umbraco/api/ContactsApi/Get')
    return {
        data: response.data.content
    }
})

export const appContactsSlice = createSlice({
    name: 'appContacts',
    initialState: {
        loading: 'idle',
        data: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                if (state.loading === 'pending') {
                    state.data = action.payload.data
                    state.loading = 'idle'
                }
            })
            .addCase(getData.pending, (state) => {
                if (state.loading === 'idle') {
                    state.loading = 'pending'
                }
            })
    }
})

export default appContactsSlice.reducer
